$theme-colors: (
  "primary": #51C7DF,
  "secondary": #F9A467,
  "success": #00bdaa,
  "warning": #ff0000,
  "light": #dee2ff,
  "gray": #cccccc,
  "dark": #0b0c23
);
$body-color:              map-get($theme-colors, "dark");
$border-radius:           0.1em;
$font-family-sans-serif:  "Quicksand", "Helvetica Neue", Arial, sans-serif;
$headings-font-family:    "Roboto", sans-serif;
$font-size-base:          1rem !default;

@import 'bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,500,700|Quicksand:400,700');


.App {
  text-align: center;
}
button {
  border-radius: 0;
}

#logo {
  width: 260px;
}
.display-5 {
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 1;
  small {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.3;
    display: block;
    margin-top: 1rem;
  }
}
#homepage {
  .hero {
    height: 70vh;
    background-position: center center;
    background-image: url("https://sdemails.s3.amazonaws.com/hero.jpg");
  }
  .imageFit {
    height: 60vh;
  } 
}
.hero {
  background-repeat: no-repeat;
  background-size: cover;
  height: 22vh;
  position: relative;
  h1 {
    z-index: 1;
    color: #ffffff;
  }
}
#pricing-page-hero {
  background-image: url("https://sdemails.s3.amazonaws.com/17311239_1890630147885088_7113332976531118667_o.jpg");
  background-position: 50% 75%;
}
#itinerary-page-hero {
  background-image: url("https://sdemails.s3.amazonaws.com/hawaii-blue-waters.jpg");
  background-position: 50% 53%;
}
#success-page-hero {
  background-image: url("https://sdemails.s3.amazonaws.com/lamborghini-huracan-sto.jpg");
  background-position: 50% 60%;
}
.header-text {
  h1 {
    font-size: 2rem;
    font-weight: 300;
  }
  p {
    font-size: 1.1rem;
  }
}
.text-large {
  font-size: 1.3rem;
  line-height: 120%;
  margin-bottom: 1rem;;
}
#pricing-page {
  .h4 {
    font-size: 1rem;
    margin: 1rem 0 0 0
  }
  small {
    margin: 0 0 0 2.5rem;
    font-weight: 500;
    font-size: 1rem;
    color: #666;
  }
  .form-check-input {
    position: relative;
    margin-left: 0;
    margin-right: 0.5rem;
  }
}
.imageFit {
  height: 40vh;
  object-fit: cover;
  border-radius: 2px;
}
#footer {
  padding: 1rem 0 4rem;
  background-color: #f0f0f0;

  a {
    color: map-get($theme-colors, "dark");
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid map-get($theme-colors, "dark");
  }
}
.popover {
  font-size: 1rem;
  border: #eee;
  box-shadow: 0 0 25px rgba(0,0,0,0.15);
}
h1, .h1 { font-size: 2.2rem }
h2, .h2 { font-size: 1.8rem }
h3, .h3 { font-size: 1.4rem }
h4, .h4 { font-size: 1.2rem }
p { font-size: 1.1rem }

.btn {
  font-weight: 700;
  padding: 0.6rem 3rem;
  text-transform: uppercase;
  &.btn-primary {
    color: #fff
  }
}
.btn-sm {
  font-size: 1rem;
  padding: 0.5rem 1.6rem;
}

.App-logo {
  height: 35vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes modalFade {
  from {transform: translateY(-15%);opacity: 0;}
  to {transform: translateY(0);opacity: 1;}
}

.modal {
  animation-name: modalFade;
  animation-duration: .6s;
}


@media (min-width: 576px) {
  .imageFit { height: 40vh }  
}

@media (min-width: 768px) {
  .imageFit { height: 55vh }  
}

@media (min-width: 1200px) {
  .imageFit { height: 60vh }  
}